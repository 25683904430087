import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-native-angular',
  templateUrl: './native-angular.component.html',
  styleUrls: ['./native-angular.component.scss']
})
export class NativeAngularComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
