import { ExportService } from './_services/export.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    visibleSidebar5;
    visibleSidebar1;
    customers: any = [];
    constructor(private exportService: ExportService) { }

    ngOnInit() {
        for (let i = 0; i <= 25; i++) {
            this.customers.push({datas: `${i}`, recebimentos: `R$ ${i}`, antecipacao: `R$ ${i}`,
            ajustes: `R$ ${i}`, cancels: `R$ ${i}`, others: `R$ ${i}`, saldo: `R$ ${i}`});
          }
    }

    export() {
        this.exportService.exportExcel(this.customers, 'customers');
      }
}

