import { ClientsFullComponent } from './examples/signup/clients-full/clients-full.component';
import { ColorsComponent } from './examples/colors/colors.component';
import { DownloadComponent } from './examples/download/download.component';
import { LogosComponent } from './examples/logos/logos.component';
import { SegmentsComponent } from './examples/segments/segments.component';
import { IllustrationsComponent } from './examples/illustrations/illustrations.component';
import { BrandedComponent } from './examples/branded/branded.component';
import { TogglesComponent } from './examples/toggles/toggles.component';
import { NavlinxComponent } from './examples/navlinx/navlinx.component';
import { ButtonsComponent } from './examples/buttons/buttons.component';
import { GeneralComponent } from './examples/general/general.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule} from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';

const routes: Routes =[
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home',             component: ComponentsComponent },
  { path: 'user-profile',     component: ProfileComponent },
  { path: 'signup', component: SignupComponent},
  {path: 'clients-full', component: ClientsFullComponent},
  { path: 'landing',          component: LandingComponent },
  { path: 'general',      component: GeneralComponent},
  { path: 'nucleoicons',      component: NucleoiconsComponent },
    { path: 'buttons',      component: ButtonsComponent},
    { path: 'toggle',      component: TogglesComponent},
    {path: 'navlinx', component: NavlinxComponent},
    {path: 'branded', component: BrandedComponent},
    {path: 'illustrations', component: IllustrationsComponent},
    {path: 'segments', component: SegmentsComponent},
    {path: 'logos', component: LogosComponent},
    {path: 'css', component: DownloadComponent},
    {path: 'colors', component: ColorsComponent}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }