import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navlinx',
  templateUrl: './navlinx.component.html',
  styleUrls: ['./navlinx.component.scss']
})
export class NavlinxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
