import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branded',
  templateUrl: './branded.component.html',
  styleUrls: ['./branded.component.scss']
})
export class BrandedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
