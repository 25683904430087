import { NativeAngularComponent } from './native-angular/native-angular.component';
import { MainComponent } from './signup/main/main.component';
import { RouterModule } from '@angular/router';
import { ClientsFullComponent } from './signup/clients-full/clients-full.component';
import { CustomersComponent } from './signup/customers/customers.component';
import { ExportDirective } from './signup/_directives/export.directive';
import { ColorsComponent } from './colors/colors.component';
import { DownloadComponent } from './download/download.component';
import { IllustrationsComponent } from './illustrations/illustrations.component';
import { LogosComponent } from './logos/logos.component';
import { SegmentsComponent } from './segments/segments.component';
import { BrandedComponent } from './branded/branded.component';
import { NavlinxComponent } from './navlinx/navlinx.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { GeneralComponent } from './general/general.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import {TogglesComponent} from './toggles/toggles.component';
import {SidebarModule} from 'primeng/sidebar';
import { HttpClientModule } from '@angular/common/http';




import {TabViewModule} from 'primeng/tabview';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        TabViewModule,
        SidebarModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule
    ],
    declarations: [
        LandingComponent,
        SignupComponent,
        ProfileComponent,
        GeneralComponent,
        ButtonsComponent,
        TogglesComponent,
        NavlinxComponent,
        BrandedComponent,
        SegmentsComponent,
        LogosComponent,
        IllustrationsComponent,
        DownloadComponent,
        ColorsComponent,
        ExportDirective,
        CustomersComponent,
        ClientsFullComponent,
        MainComponent,
        NativeAngularComponent
    ]
})
export class ExamplesModule { }
